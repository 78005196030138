import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DateRange } from '@mui/x-date-pickers-pro/models'
import dayjs, { Dayjs } from 'dayjs'

type DateRangeValue = [Dayjs | null, Dayjs | null]

type DatePickerProps = {
  value: DateRange<Dayjs>
  onChange: (value: DateRange<Dayjs>, context: any) => DateRangeValue
  name?: string
  detail?: string
}

export const DatePicker: React.FC<DatePickerProps> = ({ value, onChange, name='', detail='' }) => {
    return (
      <div className="flex flex-col">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['SingleInputDateRangeField']}>
              <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                name={name}
                value={value}
                onChange={onChange}
                sx={{borderRadius: '8px'}}
              />
            </DemoContainer>
          </LocalizationProvider>
        <p className="text-xs text-gray-600 dark:text-gray-300 mt-1">{detail}</p>
      </div>
    )
  }
