import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addToast } from '../../store/global'
import { usePasswordForgottenMutation } from '../../services/auth'
import { EmailInput } from './FormElements/EmailInput'
import { SubmitButton } from '../Buttons/index'
import { validateEmail } from '../../../../utils/validators'

export function PasswordForgottenForm() {
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)

  const dispatch = useDispatch()
  const [passwordForgotten, { isLoading }] = usePasswordForgottenMutation()

  useEffect(() => {
    setIsEmailValid(validateEmail(email))
  }, [email])
  
  const hasErrors = !isEmailValid

  const onSubmit = async () => {
    if (hasErrors) {
      dispatch(
        addToast({
          title: 'Email address is invalid',
          subtext:
            'Please make sure that your email address is correct before requesting a password reset link',
          type: 'error'
        })
      )
      return
    }

    const response = await passwordForgotten({
      username: email
    })

    if (!response?.data || response.data.error) {
      dispatch(
        addToast({
          title: 'Something went wrong',
          subtext: 'Please request a new link to reset your password',
          type: 'error'
        })
      )
      return
    }

    dispatch(
      addToast({
        title: 'Success!',
        subtext: 'Please check your email for a link to reset your password.',
        type: 'success'
      })
    )
  }

  return (
    <>
      <div className="flex flex-col text-center gap-[12px]">
        <h1 className="font-inter text-[30px] font-[600] leading-[38px] text-gray-900 dark:text-gray-100">
          Forgotten Password
        </h1>
        <span className="font-inter text-[16px] font-[400] leading-[24px] text-gray-600 dark:text-gray-400">
          Enter your email address below and we'll send you a link to reset your password.
        </span>
      </div>
      <div className="flex flex-col gap-[20px]">
        <EmailInput value={email} hasError={email && hasErrors} onChange={setEmail} />
        <SubmitButton label="Send reset password link" isLoading={isLoading} onClick={onSubmit} />
      </div>
      <div className="flex self-center">
        <span className="text-gray-600 dark:text-gray-300 text-[14px] font-[400] leading-[20px]">
          Remember your password?
          <a
            href="/"
            className="text-primary-700 dark:text-primary-400 text-[14px] font-[600] leading-[20px] pl-1"
          >
            Sign in
          </a>
        </span>
      </div>
    </>
  )
}
