import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../Table'
import { TableHead } from './TableHead'
import { TableRow } from './TableRow'
import { TableRowEmpty } from './TableRowEmpty'
import { PdfViewerModal } from '../../Modal/PdfViewerModal'
import { LoadingIcon } from '../../Icons'
import { Pagination, paginate } from '../../Pagination'
import { sortList } from '../../../helpers/sortList'
import { basicApi } from '../../../services/api'
import { Dayjs } from 'dayjs'
import { DateRange } from '@mui/x-date-pickers-pro/models'
import { DatePicker } from '../../DateFilter/DatePicker'

const fetchInvoice = async (id) => {
  const response = await basicApi('/cpanel/billing/invoices', {
    invoiceid: id
  })

  return response.blob()
}

const fetchAccountStatement = async (fromDate, toDate) => {
  const response = await basicApi('/cpanel/billing/account-statement', {
    fromDate,
    toDate
  })

  return response.blob()
}

const INVOICES_PER_PAGE = 10
const ACCOUNT_STATEMENT_ID = 'account-statement'

export function InvoiceHistory({ loading, invoices, viewAll, onToggleView }) {
  const [sortAsc, setSortAsc] = useState(false)
  const [sortBy, setSortBy] = useState('issueDate')
  const [page, setPage] = useState(1)
  const [loadingInvoiceId, setLoadingInvoiceId] = useState(null)
  const [pdfBlob, setPdfBlob] = useState(null)
  const [openPdfModal, setOpenPdfModal] = useState(false)
  const brand = useSelector((store) => store.global.brand.name)
  const [dateValue, setDateValue] = useState<DateRange<Dayjs>>([null, null])

  const loadInvoice = async (id) => {
    setLoadingInvoiceId(id)
    const blob = await fetchInvoice(id)
    setPdfBlob(blob)
    setLoadingInvoiceId(null)
    setOpenPdfModal(true)
  }

  const loadAccountStatement = async () => {
    const [start, end] = dateValue // Date value from DateRangePicker
    const date2YearsAgo = new Date().setFullYear(new Date().getFullYear() - 2)
    const fromDate = start ? Math.round(start.toDate().getTime() / 1000) : Math.round(new Date(date2YearsAgo).getTime() / 1000) // 2 years ago
    const toDate = end ? Math.round(end.toDate().getTime() / 1000) : Math.round(new Date().getTime() / 1000) // now
    setLoadingInvoiceId(ACCOUNT_STATEMENT_ID)
    const blob = await fetchAccountStatement(fromDate, toDate)
    setPdfBlob(blob)
    setLoadingInvoiceId(null)
    setOpenPdfModal(true)
  }

  const onToggleClick = () => {
    setPage(1)
    onToggleView()
  }

  const onSortClick = (name) => {
    setSortAsc(sortBy === name ? !sortAsc : false)
    setSortBy(name)
  }

  const isLoadingStatement = loadingInvoiceId === ACCOUNT_STATEMENT_ID

  const sortedData = useMemo(() => {
    return sortList(invoices, sortBy, sortAsc)
  }, [invoices, sortBy, sortAsc])
  const { total, paginated } = paginate(sortedData, page, INVOICES_PER_PAGE)

  const rows = useMemo(() => {

  return paginated.map((invoice) => (
      <TableRow
        key={invoice.id}
        invoice={invoice}
        loading={loadingInvoiceId === invoice.id}
        onInvoiceClick={loadInvoice}
      />
    ))
  }, [paginated, loadingInvoiceId])
  

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-4 mb-[20px] justify-center items-baseline">
        <button
          type="button"
          className={`
          flex w-full sm:w-fit sm:px-[70px] xl:px-[100px] mb-[10px] py-[10px] items-center justify-center gap-[10px] rounded-[8px] text-center font-[600] text-[16px] leading-[24px] bg-primary-200 dark:bg-primary-600 hover:bg-primary-300 dark:hover:bg-primary-500 shadow-none
          ${brand === 'jdm' ? 'dark:hover:text-black bg-primary-400 hover:bg-primary-500 dark:bg-primary-300 dark:hover:bg-primary-500' : 'text-white bg-primary-700 dark:bg-primary-300 hover:bg-primary-900 dark:hover:bg-primary-500'}`}
          disabled={isLoadingStatement}
          onClick={loadAccountStatement}
        >
          {isLoadingStatement ? 'Loading...' : 'Account Statement'}
          {isLoadingStatement ? <LoadingIcon /> : ''}
        </button>
        <div className="flex-grow w-full sm:flex-shrink-0 sm:w-fit">
          <DatePicker value={dateValue} onChange={setDateValue} name="account-statement-date-range" detail='Select date range for account statement'/>
        </div>
      </div>
      <Table
        head={<TableHead onSortClick={onSortClick} sortBy={sortBy} sortAsc={sortAsc} />}
        rows={rows.length > 0 ? rows : <TableRowEmpty loading={loading} />}
      />
      <Pagination total={total} page={page} perPage={INVOICES_PER_PAGE} onClick={setPage} />
      {openPdfModal && (
        <PdfViewerModal
          pdfBlob={pdfBlob}
          headerText="Invoice"
          onClose={() => setOpenPdfModal(false)}
        />
      )}
    </>
  )
}
