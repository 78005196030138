import React, { useEffect, useState } from 'react'
import { Outlet, useMatches } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProviderComponent } from '../ThemeContext'
import { store } from '../store'
import { PageType } from './PageType'
import { Layout } from '../components/Layout/Layout'
import { withAuthGuard } from './withAuthGuard'
import { LicenseInfo } from '@mui/x-license'

const LayoutWithAuthGuard = withAuthGuard(Layout)

// This is a set of pages that shouldn't be rerendered when the version of the JS file changes
const NO_RERENDER_PAGES = new Set([
  PageType.Contact,
  PageType.Password,
  PageType.Company,
  PageType.Preferences,
  PageType.Connections,
  PageType.Website
])

export function AppRoot() {
  const matches = useMatches()
  const pageType = matches[matches.length - 1]?.id || PageType.Dashboard
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY) // Set the license key for MUI X components
  }, [])

  const checkFileVersion = async () => {
    setLoading(true)
    const currentFileName = window.loadedJS

    try {
      const response = await fetch(currentFileName, { method: 'HEAD' })
      if (!response.ok) {
        window.location.reload()
        return
      }
      setLoading(false)
    } catch (error) {
      window.location.reload()
    }
  }

  useEffect(() => {
    // This is a temporary solution to prevent flickering in settings tabs
    // Find a better way to handle this + checking file version
    if (NO_RERENDER_PAGES.has(pageType)) return
    checkFileVersion()
  }, [pageType])

  return (
    <Provider store={store}>
      <ThemeProviderComponent>
        <LayoutWithAuthGuard pageType={pageType} loading={loading}>
          <Outlet />
        </LayoutWithAuthGuard>
      </ThemeProviderComponent>
    </Provider>
  )
}
